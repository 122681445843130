import { IConfig } from "./config";

export const localConfig: IConfig = {
  API: "https://api.dhub.arup.com/pdg",
  S3_IMAGES: "https://dhub-pdg-prod.s3-eu-west-1.amazonaws.com/",
  TOKEN_STORAGE: "arup_login_microservice_user",
  REFRESH_TOKEN_STORAGE: "arup_login_microservice_user_refresh",
  LOGIN_REDIRECT: "https://d351b7s23jrl81.cloudfront.net/",
  APP_NAME: "dhub-pdg",
  COGNITO_POOL_ID: "eu-west-1_AoTfeHEFm",
  COGNITO_CLIENT_ID: "15t9296ccignmlbe9hblf30a04",
};
