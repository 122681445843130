import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";

export interface IUserState {
  tokenInfo: any;
}

const initialState: IUserState = {
  tokenInfo: null,
};

export const userReducer: Reducer<IUserState> = (
  state = initialState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "ADD_USER": {
      return {
        ...state,
        tokenInfo: action.rawData,
      };
    }
    default:
      return state;
  }
};
