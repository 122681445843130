import { createMuiTheme } from "@material-ui/core/styles";

// For all vars available to config Material-UI theme:
// https://v3.material-ui.com/customization/themes/
export const dHubTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
      light: "#F7F7F9",
      dark: "#c2c8c7",
      contrastText: "#000000",
    },
    secondary: {
      main: "#E61E28",
      dark: "#31BEB9",
    },
    background: {
      default: "#ffffff",
      paper: "#F8F8F8",
    },
    text: {
      primary: "#243533",
      secondary: "#8F9796",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        height: "2.5rem",
        backgroundColor: "#7CCDEF",
        borderRadius: "1.25rem",
        boxShadow: "0px 5px 10px #00000029",
        "&:hover": {
          backgroundColor: "#0092D1",
        },
      },
      outlinedPrimary: {
        height: "2.5rem",
        backgroundColor: "transparent",
        borderRadius: "1.25rem",
        "&:hover": {
          backgroundColor: "#0092D1",
          color: "#ffffff",
        },
      },
    },
    MuiTableCell: {
      root: { fontSize: "1rem", height: "fit-content", padding: "0.75rem 0" },
      head: {
        color: "#E61E28",
        fontWeight: "bold",
        borderColor: "#243533",
        textTransform: "capitalize",
      },
    },
  },
  typography: {
    fontFamily: "Roboto, Open Sans, Helvetica, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    h3: { fontSize: "1.375rem" },
    h4: {
      fontSize: "1.5rem",
      fontFamily: "Times New Roman, Arial, sans-serif",
      fontWeight: 500,
      letterSpacing: -0.13,
      lineHeightStep: "1.31rem",
    },
    body1: {
      fontWeight: 500,
      lineHeight: 1.7,
      letterSpacing: -0.06,
      fontSize: "1rem",
      lineHeightStep: "0.9rem",
    },
    body2: {
      fontSize: "0.6rem",
      letterSpacing: 0.28,
      lineHeightStep: "0.687rem",
    },
    button: {
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "capitalize",
      letterSpacing: 0.32,
    },
  },
});

dHubTheme.shadows[24] = "0px 5px 10px #00000029";
