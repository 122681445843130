import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import SelectFilter from "./SelectFilter";
import ProgressBar from "./ProgressBar";
import DateRangeSlider from "./DateRangeSlider";
import { useDispatch, useSelector } from "react-redux";
import { dHubTheme } from "./../../config/mui";
import { RootState } from "../../store/reducer";
import { incidencesActions } from "../../store/incidence";
import { format, isWithinInterval } from "date-fns";
import { convertPriority } from "../Content/utils";
import CheckboxGroup from "./CheckboxGroup";
import FilterHoC from "./FilterHoC";

export interface IActiveFilters {
  system: string[];
  floor: string[];
  zone: string[];
  isResolved: string[];
  priority: string[];
  openDate: number[];
}

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: "1.5rem",
      gridColumn: "1/4",
      borderRadius: "0.3rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    chartGroupTitle: {
      marginTop: "1.7rem",
    },
    filterGrids: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  });

type ClassKey = "root" | "chartGroupTitle" | "filterGrids";
type PropsType = IProps & WithStyles<ClassKey>;

const FilterPane: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();

  const incidencesFromStore = useSelector(
    (state: RootState) => state.incidence.incidencesList
  );

  const filteredFromStore = useSelector(
    (state: RootState) => state.incidence.filteredIncidences
  );

  const [activeFilters, setActiveFilters] = useState<any>({
    system: [],
    floor: [],
    zone: [],
    isResolved: [],
    priority: [],
    openDate: [],
  });

  const handleSelection = (
    keySearch: string,
    criteria: any[],
    currentActiveFilters: any
  ) => {
    const copy = { ...currentActiveFilters };
    copy[keySearch] = criteria;
    setActiveFilters(copy);
  };

  useEffect(() => {
    const filtersToApply = Object.keys(activeFilters);
    let filtered = [...incidencesFromStore];

    filtersToApply.forEach((filterToApply) => {
      if (activeFilters[filterToApply].length === 0) return;

      if (
        filterToApply === "openDate" &&
        activeFilters[filterToApply][0] !== null
      ) {
        filtered = filtered.filter((incidence) => {
          const convertedDate = new Date(
            format(new Date(incidence.openDate), "MMM/yyyy")
          );
          const dates = activeFilters[filterToApply].map(
            (date: string) => new Date(date)
          );
          if (
            isWithinInterval(convertedDate, {
              start: dates[0],
              end: dates[1],
            })
          )
            return incidence;
          // eslint-disable-next-line array-callback-return
          return;
        });
      } else
        filtered = filtered.filter((x) =>
          activeFilters[filterToApply].includes(
            (x as any)[filterToApply] as string
          )
        );
    });
    dispatch(incidencesActions.filterIncidencesList(filtered));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const statusCalculations = () => {
    const total = filteredFromStore.length;
    return {
      pending: filteredFromStore.filter(
        (incidence) => incidence.isResolved === "No resuelto"
      ).length,
      resolved: filteredFromStore.filter(
        (incidence) => incidence.isResolved === "Resuelto"
      ).length,
      percentagePending:
        (filteredFromStore.filter(
          (incidence) => incidence.isResolved === "No resuelto"
        ).length /
          total) *
        100,
      percentageResolved:
        (filteredFromStore.filter(
          (incidence) => incidence.isResolved === "Resuelto"
        ).length /
          total) *
        100,
    };
  };

  const incidencePriorityCalculations = () => {
    const total = filteredFromStore.length;
    return {
      high: filteredFromStore.filter(
        (incidence) => convertPriority(incidence.priority) === 3
      ).length,
      medium: filteredFromStore.filter(
        (incidence) => convertPriority(incidence.priority) === 2
      ).length,
      low: filteredFromStore.filter(
        (incidence) => convertPriority(incidence.priority) === 1
      ).length,
      percentageHigh:
        (filteredFromStore.filter(
          (incidence) => convertPriority(incidence.priority) === 3
        ).length /
          total) *
        100,
      percentageMedium:
        (filteredFromStore.filter(
          (incidence) => convertPriority(incidence.priority) === 2
        ).length /
          total) *
        100,
      percentageLow:
        (filteredFromStore.filter(
          (incidence) => convertPriority(incidence.priority) === 1
        ).length /
          total) *
        100,
    };
  };

  const discardRepeated = (list: any[]) =>
    list.reduce<any>((acc, current) => {
      if (!acc.includes(current)) acc.push(current);
      return acc;
    }, []);

  return (
    <div className={classes.root}>
      <div className={classes.filterGrids}>
        {incidencesFromStore.length > 0 && (
          <FilterHoC title="Plantas del edificio" columnSpan="1/4">
            <SelectFilter
              filterOptions={discardRepeated(
                incidencesFromStore.map((incidence) => incidence.floor)
              )}
              filterKey="floor"
              handleSelection={handleSelection}
              activeFilters={activeFilters}
            />
          </FilterHoC>
        )}
        {incidencesFromStore.length > 0 && (
          <FilterHoC title="Zonas de la planta" columnSpan="1/4">
            <SelectFilter
              filterOptions={discardRepeated(
                incidencesFromStore.map((incidence) => incidence.zone)
              )}
              filterKey="zone"
              handleSelection={handleSelection}
              activeFilters={activeFilters}
            />
          </FilterHoC>
        )}
        <FilterHoC title="Sistemas" columnSpan="1/4">
          <CheckboxGroup
            filterOptions={discardRepeated(
              incidencesFromStore.map((incidence) => incidence.system)
            )}
            filterKey="system"
            handleSelection={handleSelection}
            activeFilters={activeFilters}
          />
        </FilterHoC>
        <FilterHoC title="Subsistemas" columnSpan="1/4">
          <CheckboxGroup
            filterOptions={discardRepeated(
              incidencesFromStore.map((incidence) => incidence.subsystem)
            )}
            filterKey="subsystem"
            handleSelection={handleSelection}
            activeFilters={activeFilters}
          />
        </FilterHoC>
        <FilterHoC title="Tipologia" columnSpan="1/2">
          <CheckboxGroup
            filterOptions={discardRepeated(
              incidencesFromStore.map((incidence) => incidence.type)
            )}
            filterKey="type"
            handleSelection={handleSelection}
            activeFilters={activeFilters}
            customHeight="3rem"
          />
        </FilterHoC>
        <FilterHoC title="Estado" columnSpan="2/3">
          <CheckboxGroup
            filterOptions={discardRepeated(
              incidencesFromStore.map((incidence) => incidence.isResolved)
            )}
            filterKey="isResolved"
            handleSelection={handleSelection}
            activeFilters={activeFilters}
            customHeight="3rem"
          />
        </FilterHoC>
        <FilterHoC title="Prioridad" columnSpan="3/4">
          <CheckboxGroup
            filterOptions={discardRepeated(
              incidencesFromStore.map((incidence) => incidence.priority)
            )}
            filterKey="priority"
            handleSelection={handleSelection}
            activeFilters={activeFilters}
            customHeight="3rem"
          />
        </FilterHoC>
        {incidencesFromStore.length > 0 && (
          <FilterHoC title="Periodo incidencias" columnSpan="1/4">
            <DateRangeSlider
              filterKey="openDate"
              handleSelection={handleSelection}
              activeFilters={activeFilters}
            />
          </FilterHoC>
        )}
      </div>
      <div>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: 700 }}
          className={classes.chartGroupTitle}
        >
          Estado de las incidencias
        </Typography>
        <ProgressBar
          percentage={statusCalculations().percentagePending}
          color={dHubTheme.palette.secondary.main}
          title={"Pendientes"}
          label={statusCalculations().pending + ""}
        />
        <ProgressBar
          percentage={statusCalculations().percentageResolved}
          color={dHubTheme.palette.secondary.dark}
          title={"Resueltas"}
          label={statusCalculations().resolved + ""}
        />
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: 700 }}
          className={classes.chartGroupTitle}
        >
          Nivel de prioridad de las incidencias
        </Typography>
        <ProgressBar
          percentage={incidencePriorityCalculations().percentageHigh}
          color={dHubTheme.palette.primary.dark}
          title={"Alta"}
          label={incidencePriorityCalculations().high + ""}
        />
        <ProgressBar
          percentage={incidencePriorityCalculations().percentageMedium}
          color={dHubTheme.palette.text.secondary}
          title={"Media"}
          label={incidencePriorityCalculations().medium + ""}
        />
        <ProgressBar
          percentage={incidencePriorityCalculations().percentageLow}
          color={dHubTheme.palette.text.primary}
          title={"Baja"}
          label={incidencePriorityCalculations().low + ""}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(FilterPane);
