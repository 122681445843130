import React from "react";
import { useHistory } from "react-router-dom";
import jwt, { JwtPayload } from "jsonwebtoken";

import config from "../config/config";

interface IProps {}

const Auth: React.FC<IProps> = (props) => {
  const history = useHistory();
  const search = history.location.search;
  const idToken = new URLSearchParams(search).get("idToken");
  const recievedRefreshToken = new URLSearchParams(search).get("refreshToken");

  const loginUrl = `${config.LOGIN_REDIRECT}?nextUrl=${window.location.origin}&poolid=${config.COGNITO_POOL_ID}&clientid=${config.COGNITO_CLIENT_ID}&app=${config.APP_NAME}`;

  if (idToken) {
    localStorage.setItem(config.TOKEN_STORAGE, idToken);
  }
  if (recievedRefreshToken) {
    localStorage.setItem(config.REFRESH_TOKEN_STORAGE, recievedRefreshToken);
  }

  if (
    !localStorage.getItem(config.TOKEN_STORAGE) ||
    !localStorage.getItem(config.REFRESH_TOKEN_STORAGE)
  )
    window.location.replace(loginUrl);

  const jwtFromLocalStorage = jwt.decode(
    localStorage.getItem(config.TOKEN_STORAGE)!
  );

  if (!jwtFromLocalStorage) {
    window.location.replace(loginUrl);
  } else {
    const hasExpired: boolean =
      (jwtFromLocalStorage as JwtPayload).exp! <
      Math.trunc(new Date().getTime() / 1000);
    if (hasExpired) {
      window.location.replace(loginUrl);
    } else {
      if (window.location.search !== "") window.location.search = "";
    }
  }

  return <div>{props.children}</div>;
};

export default Auth;
