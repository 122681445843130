import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import arupLogo from "./../assets/images/Arup_Red_RGB.svg";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      padding: "1.2rem 2.375rem 1.2rem 4.375rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: theme.palette.primary.main,
      boxShadow: "0 0px 3px 2px #cccccc33",
    },
    appName: {
      "& > h4": {
        display: "inline-block",
      },
    },
    logo: {
      height: "1.5rem",
    },
  });

type ClassKey = "root" | "appName" | "logo";
type PropsType = IProps & WithStyles<ClassKey>;

const Topbar: React.FC<PropsType> = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.appName}>
        <Typography variant={"h4"} color="secondary">
          d.Hub
        </Typography>
        &nbsp;
        <Typography variant={"h4"}>Passeig de Gràcia 109-111</Typography>
      </div>
      <img src={arupLogo} alt="Arup logo" className={classes.logo} />
    </div>
  );
};

export default withStyles(styles)(Topbar);
