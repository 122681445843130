import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  InputBase,
  Select,
  MenuItem,
  Checkbox,
  Divider,
} from "@material-ui/core";

import { IActiveFilters } from "./FilterPane";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    borderRadius: "0.31rem",
    position: "relative",
    border: `1px solid ${theme.palette.primary.dark}`,
    fontSize: "0.8rem",
    padding: "10px 26px 10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "0.31rem",
    },
  },
}))(InputBase);

interface IProps {
  filterOptions: any[];
  filterKey: string;
  activeFilters: IActiveFilters;
  handleSelection: (
    keySearch: string,
    criteria: string[],
    currentActiveFilters: IActiveFilters
  ) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const SelectFilter: React.FC<PropsType> = (props) => {
  const {
    classes,
    filterOptions,
    filterKey,
    activeFilters,
    handleSelection,
  } = props;

  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true);

  const handleChange = (event: BaseSyntheticEvent) => {
    const selectionAll =
      (event.nativeEvent as any).target.dataset.value === "all";
    const selection = event.target.value as string[];
    if (selectionAll) {
      isAllSelected ? deselectAll() : selectAll();
      return;
    }
    setIsAllSelected(checkAllSelected(selection));
    handleSelection(filterKey, selection, activeFilters);
    setOptionsSelected(selection);
  };

  const checkAllSelected = (currentSelection: string[]) =>
    filterOptions.every((x) => currentSelection.includes(x));

  const selectAll = () => {
    setOptionsSelected([...filterOptions]);
    handleSelection(filterKey, [...filterOptions], activeFilters);
    setIsAllSelected(true);
  };

  const deselectAll = () => {
    setOptionsSelected([]);
    handleSelection(filterKey, [], activeFilters);
    setIsAllSelected(false);
  };

  useEffect(
    () => selectAll(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={classes.root}>
      <Select
        multiple
        value={optionsSelected}
        onChange={handleChange}
        input={<CustomInput />}
        renderValue={(selected) => {
          const customSelected = selected as string[];
          if (checkAllSelected(customSelected)) return "Todos los elementos";
          else return (selected as string[]).join(", ");
        }}
      >
        <MenuItem value={"all"}>
          <Checkbox checked={isAllSelected} />
          Todos los elementos
        </MenuItem>
        <Divider />
        {filterOptions.map((option) => (
          <MenuItem
            key={`elementFilter_${option}_${Math.random()}`}
            value={option}
          >
            <Checkbox checked={optionsSelected.indexOf(option) > -1} />
            {typeof option === "boolean"
              ? option
                ? "Resuelta"
                : "No resuelta"
              : option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default withStyles(styles)(SelectFilter);
