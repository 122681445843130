import { combineReducers } from "redux";
import { incidenceReducer } from "./incidence/reducer";
import { userReducer } from "./user/reducer";

export const rootReducer = combineReducers({
  incidence: incidenceReducer,
  user: userReducer,
});

/*eslint no-undef: "off"*/
export type RootState = ReturnType<typeof rootReducer>;
