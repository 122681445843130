import { IIncidence } from "./../interfaces";
import { AppThunk, IRawSection } from "../interfaces";

export const incidencesActions = {
  addIncidencesList: (rawData: [IRawSection[]]): AppThunk => (dispatch) => {
    dispatch({
      type: "ADD_INCIDENCES_LIST",
      rawData,
    });
  },
  filterIncidencesList: (filtered: IIncidence[]): AppThunk => (dispatch) => {
    dispatch({
      type: "FILTER_INCIDENCES_LIST",
      filtered,
    });
  },
};
