import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Slider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { RootState } from "../../store/reducer";
import { isAfter } from "date-fns/esm";
import { IActiveFilters } from "./FilterPane";

const CustomSlider = withStyles((theme: Theme) => ({
  thumb: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.text.primary}`,
    transform: "scale(1.2)",
  },
  track: {
    backgroundColor: theme.palette.text.primary,
  },
  valueLabel: {
    top: "200%",
    "& *": {
      fontSize: ".7rem",
      background: "transparent",
      fontFamily: "Roboto, Helvetica, Open Sans, Arial, sans-serif",
    },
  },
  markLabel: {
    color: "white",
  },
}))(Slider);

interface IProps {
  filterKey: string;
  activeFilters: IActiveFilters;
  handleSelection: (
    keySearch: string,
    criteria: any[],
    currentActiveFilters: IActiveFilters
  ) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: "0 0.5rem",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const DateRangeSlider: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const incidencesFromStore = useSelector(
    (state: RootState) => state.incidence.incidencesList
  );

  const [dates, setDates] = useState<number[]>([0, 0]);
  const [dateRange, setDateRange] = useState<any>([]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setDates(newValue as number[]);
  };

  const convertToRange = () => {
    let _dates: {
      label: string;
      value: number;
    }[] = incidencesFromStore.map((incidence, index) => ({
      label: format(new Date(incidence.openDate), "yyyy-MM-dd"),
      value: 0,
    }));

    _dates = _dates.reduce<any>((acc, current) => {
      if (!acc.map((x: any) => x.label).includes(current.label))
        acc.push(current);
      return acc;
    }, []);

    _dates = _dates.sort((a, b) => {
      const aDate = new Date(a.label);
      const bDate = new Date(b.label);

      if (isAfter(aDate, bDate)) return 1;
      return -1;
    });

    _dates = _dates.map((date, index) => ({
      ...date,
      value: _dates.length > 1 ? index * (100 / (_dates.length - 1)) : 0,
    }));

    setDates([0, _dates[_dates.length - 1].value]);
    setDateRange(_dates);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => convertToRange(), [incidencesFromStore]);

  useEffect(
    () => {
      if (dates.length && dateRange.length)
        props.handleSelection(
          props.filterKey,
          [
            dateRange.find((x: any) => x.value === dates[0]).label,
            dateRange.find((x: any) => x.value === dates[1]).label,
          ],
          props.activeFilters
        );
      else props.handleSelection(props.filterKey, [], props.activeFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dates]
  );

  const valueLabelFormat = (value: number) => {
    if (!dateRange.find((x: any) => x.value === value)) return "";
    return (
      format(
        new Date(dateRange.find((x: any) => x.value === value)?.label),
        "MMM/yy"
      ) || ""
    );
  };

  return (
    <div className={classes.root}>
      <CustomSlider
        color="secondary"
        value={dates}
        onChange={handleChange}
        step={null}
        marks={dateRange}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="on"
      />
    </div>
  );
};

export default withStyles(styles)(DateRangeSlider);
