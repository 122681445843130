import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {
  percentage: number;
  color: string;
  title: string;
  label: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    indicatorContainer: {
      width: "100%",
      display: "inline-block",
    },
    indicatorBar: {
      width: "100%",
      height: "1rem",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "0.25rem",
      position: "relative",
    },
    indicatorLabel: {
      marginLeft: "1rem",
    },
    indicatorWithLabel: {
      display: "flex",
      alignItems: "center",
    },
  });

type ClassKey =
  | "root"
  | "indicatorContainer"
  | "indicatorBar"
  | "indicatorLabel"
  | "indicatorWithLabel";
type PropsType = IProps & WithStyles<ClassKey>;

const ProgressBar: React.FC<PropsType> = (props) => {
  const { classes, percentage, color, title, label } = props;
  return (
    <div className={classes.root}>
      <Typography variant="body1">{title}</Typography>
      <div className={classes.indicatorWithLabel}>
        <div
          className={classes.indicatorBar}
          style={{ backgroundColor: color, width: `${percentage}%` }}
        />
        <Typography
          variant="h3"
          className={classes.indicatorLabel}
          style={{ color: color }}
        >
          {label}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProgressBar);
