import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";

import config from "./../../../config/config";
import { IIncidence } from "../../../store/interfaces";
import clsx from "clsx";
interface IProps {
  data: IIncidence;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1.3rem 0",
    },
    incidenceDescription: {
      width: "33%",
      marginRight: "3rem",
    },
    incidenceImages: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "&>img": {
        height: "10rem",
        objectFit: "cover",
        marginTop: "1rem",
        marginLeft: "1rem",
        aspectRatio: "1/1",
        transition: "all 0.3s",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.1)",
        },
      },
    },
    incidenceDescriptionDates: {
      marginBottom: "2rem",
    },
    incidenceImageResolved: {
      border: `2px solid ${theme.palette.secondary.dark}`,
    },
    incidenceImageNoResolved: {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  });

type ClassKey =
  | "root"
  | "incidenceDescription"
  | "incidenceDescriptionDates"
  | "incidenceImages"
  | "incidenceImageResolved"
  | "incidenceImageNoResolved";
type PropsType = IProps & WithStyles<ClassKey>;

const CollapseInfo: React.FC<PropsType> = (props) => {
  const { classes, data } = props;
  return (
    <div className={classes.root}>
      <div className={classes.incidenceDescription}>
        <div className={classes.incidenceDescriptionDates}>
          <Typography variant="body1">
            <b>Fecha incidencia:</b>&nbsp;
            {format(new Date(data.openDate), "dd.MM.yyyy")}
          </Typography>
          {data.resolveDate && (
            <Typography variant="body1">
              <b>Fecha resolución:</b>&nbsp;
              {format(new Date(data.resolveDate), "dd.MM.yyyy")}
            </Typography>
          )}
        </div>
        <Typography variant="body1">
          <b>Comentario:</b>&nbsp;{data.comment}
        </Typography>
      </div>
      <div className={classes.incidenceImages}>
        {data.images.map((image, id) => (
          <img
            src={`${config.S3_IMAGES}${image.url}.jpg`}
            key={id + "" + image.url}
            alt="Incidence"
            title={image.type === 0 ? "Issue photo" : "Resolution photo"}
            onClick={() => window.open(`${config.S3_IMAGES}${image.url}.jpg`)}
            className={clsx(
              image.type === 0
                ? classes.incidenceImageNoResolved
                : classes.incidenceImageResolved
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(CollapseInfo);
