import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

import CustomTable from "./CustomTable/Table";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: "1.5rem",
      gridColumn: "4/13",
      borderRadius: "0.3rem",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ContentContainer: React.FC<PropsType> = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <CustomTable />
    </div>
  );
};

export default withStyles(styles)(ContentContainer);
