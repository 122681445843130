import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface IProps {
  errorCode: number;
  customMessage?: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifycontent: "center",
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "fit-contents",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ErrorComponent: React.FC<PropsType> = (props) => {
  const { classes, errorCode, customMessage } = props;

  const composeErrorMessage = () => {
    switch (errorCode) {
      case 401:
        return "Authentication failed. Please try again.";
      case 403:
        return "Forbidden access. If you think it's an error, contact the development team at digitalmadrid@arup.com";
      case 404:
        return "Resource not found.";
      case 405:
        return "Can't achive request. Please try again. If the problem persists, contact the development team at digitalmadrid@arup.com";
      case 500:
        return "Internal server error.";
      default:
        return "An error ocurred. If the problem persists, please contact the development team at digitalmadrid@arup.com";
    }
  };

  return (
    <div className={classes.root}>
      <ErrorOutlineIcon color="secondary" fontSize="large" />
      <Typography variant={"h4"} color="secondary">
        {customMessage ? customMessage : composeErrorMessage()}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ErrorComponent);
