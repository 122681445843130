import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  CircularProgress,
  Typography,
} from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifycontent: "center",
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "fit-contents",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const Loading: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const [text, setText] = useState("Cargando contenido");

  useEffect(() => {
    const start = new Date();
    start.setSeconds(start.getSeconds() + 15);
    const interval = setInterval(() => {
      if (new Date() > start) {
        setText(
          "Por favor, espere unos minutos mientras actualizamos la información."
        );
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" size="2.5rem" />
      <Typography variant={"body1"}>{text}</Typography>
    </div>
  );
};

export default withStyles(styles)(Loading);
