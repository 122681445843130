import { Action, Reducer } from "redux";

import { IIncidence, IRawSection } from "./../interfaces";
import { KnownAction } from "./actions";

export interface IIncidenceState {
  rawData: IRawSection[][];
  incidencesList: IIncidence[];
  filteredIncidences: IIncidence[];
}

const initialState: IIncidenceState = {
  rawData: [],
  incidencesList: [],
  filteredIncidences: [],
};

export const incidenceReducer: Reducer<IIncidenceState> = (
  state = initialState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "ADD_INCIDENCES_LIST": {
      let incidences = [...action.rawData];

      incidences = typeof incidences !== "string" ? incidences : [];

      const convertedIncidences = incidences.map<IIncidence>((incidence) =>
        convertToIncidence(incidence as [IRawSection])
      );
      return {
        ...state,
        rawData: incidences,
        incidencesList: convertedIncidences,
        filteredIncidences: convertedIncidences,
      };
    }
    case "FILTER_INCIDENCES_LIST": {
      return {
        ...state,
        filteredIncidences: [...action.filtered],
      };
    }
    default:
      return state;
  }
};

const convertToIncidence = (rawIncidence: [IRawSection]): IIncidence => {
  const generalInformation = rawIncidence[0];
  const elements = (generalInformation.elements as any[]) || [];

  try {
    return {
      name: searchForObjectWithDataName("nombre_de_la_incidencia", elements)
        .value! as string,
      openDate: searchForObjectWithDataName("fecha", elements).value! || "",
      floor: searchForObjectWithDataName("localizacion", elements).value![0],
      zone: searchForObjectWithDataName("localizacion", elements).value![2],
      system: searchForObjectWithDataName("sistema", elements).value![0],
      subsystem: searchForObjectWithDataName("sistema", elements).value![1],
      type: searchForObjectWithDataName("tipo", elements).value![0],
      resolveDate:
        (searchForObjectWithDataName("fecha_resolucion", elements)
          .value as string) || "",
      isResolved:
        searchForObjectWithDataName("resuelto", elements).value === "Yes"
          ? "Resuelto"
          : "No resuelto",
      priority: searchForObjectWithDataName("prioridad", elements).value![0],
      comment:
        (searchForObjectWithDataName("comentario", elements).value as string) ||
        "",
      images: [
        ...((searchForObjectWithDataName("fotos", elements).value! ||
          []) as any[]).map<{ type: 0 | 1; url: string }>((x) => ({
          type: 0,
          url: x.photo_id,
        })),
        ...((searchForObjectWithDataName("fotos_resolucion", elements).value! ||
          []) as any[]).map<{ type: 0 | 1; url: string }>((x) => ({
          type: 1,
          url: x.photo_id,
        })),
      ],
    };
  } catch (e) {
    console.log(e);
    return {
      name: "",
      openDate: "",
      floor: "",
      zone: "",
      system: "",
      subsystem: "",
      type: "",
      resolveDate: "",
      isResolved: "",
      priority: "",
      comment: "",
      images: [],
    };
  }
};

const searchForObjectWithDataName = (
  dataName: string,
  list: any[]
): any | Error =>
  list.find((object) => object.data_name.includes(dataName)) ||
  new Error(`Object with dataname ${dataName} not found`);
