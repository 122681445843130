import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

import FilterPane from "./FilterPane/FilterPane";
import ContentContainer from "./Content/ContentContainer";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: "2rem 2.375rem",
      display: "grid",
      gridTemplateColumns: "repeat(12,1fr)",
      gap: 24,
      flex: 1,
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const Frame: React.FC<PropsType> = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <FilterPane />
      <ContentContainer />
    </div>
  );
};

export default withStyles(styles)(Frame);
