import { localConfig } from "./local";
import { production } from "./prod";

export interface IConfig {
  TOKEN_STORAGE: string;
  REFRESH_TOKEN_STORAGE: string;
  LOGIN_REDIRECT: string;
  APP_NAME: string;
  COGNITO_POOL_ID: string;
  COGNITO_CLIENT_ID: string;
  API: string;
  S3_IMAGES: string;
}

const location = window.location.hostname;

let config: IConfig;
if (location === "localhost") config = localConfig;
else config = production;

export default config;
