import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { dHubTheme } from "./../../../config/mui";
import CollapseInfo from "./CollapseInfo";
import { IIncidence } from "../../../store/interfaces";
import { convertPriority } from "./../utils";

interface IProps {
  data: IIncidence;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    statusIndicatorContainer: {
      display: "flex",
      alignItems: "center",
    },
    statusIndicatorBase: {
      width: "1rem",
      height: "1rem",
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: "50%",
      marginRight: "0.5rem",
    },
  });

type ClassKey = "root" | "statusIndicatorContainer" | "statusIndicatorBase";
type PropsType = IProps & WithStyles<ClassKey>;

const CustomTableRow: React.FC<PropsType> = (props) => {
  const { classes, data } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>{data.name}</TableCell>
        <TableCell>{data.system}</TableCell>
        <TableCell>{data.subsystem}</TableCell>
        <TableCell>{data.type}</TableCell>
        <TableCell>{data.floor}</TableCell>
        <TableCell>{data.zone}</TableCell>
        <TableCell>
          <div className={classes.statusIndicatorContainer}>
            <div
              className={classes.statusIndicatorBase}
              style={{
                ...(convertPriority(data.priority) >= 1 && {
                  backgroundColor: dHubTheme.palette.primary.dark,
                }),
              }}
            />
            <div
              className={classes.statusIndicatorBase}
              style={{
                ...(convertPriority(data.priority) >= 2 && {
                  backgroundColor: dHubTheme.palette.text.secondary,
                }),
              }}
            />
            <div
              className={classes.statusIndicatorBase}
              style={{
                ...(convertPriority(data.priority) === 3 && {
                  backgroundColor: dHubTheme.palette.text.primary,
                }),
              }}
            />
          </div>
        </TableCell>
        <TableCell>
          <CancelIcon
            style={{
              fill:
                data.isResolved === "No resuelto"
                  ? dHubTheme.palette.secondary.main
                  : dHubTheme.palette.primary.light,
            }}
          />
          <CheckCircleIcon
            style={{
              fill:
                data.isResolved === "Resuelto"
                  ? dHubTheme.palette.secondary.dark
                  : dHubTheme.palette.primary.light,
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <CollapseInfo data={data} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default withStyles(styles)(CustomTableRow);
