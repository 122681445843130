import React from "react";
import axios from "axios";
import jwt from "jsonwebtoken";

import config from "../config/config";

interface IProps {}

export const refreshToken = (refreshToken: string) =>
  axios.post(config.LOGIN_REDIRECT + `/oauth2/token/`, null, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: config.COGNITO_CLIENT_ID,
    },
  });

export const decodeToken = (token: string) => jwt.decode(token);

export const isTokenExpired = (token: string) => {
  const payload: any = decodeToken(token)!;
  return payload["exp"] < Math.trunc(new Date().getTime() / 1000);
};

const Axios: React.FC<IProps> = (props) => {
  axios.interceptors.request.use(async (request) => {
    if (request.url?.includes(config.API)) {
      let idToken = localStorage.getItem(config.TOKEN_STORAGE)!;
      if (isTokenExpired(idToken)) {
        const response = await refreshToken(
          localStorage.getItem(config.REFRESH_TOKEN_STORAGE)!
        );
        idToken = response.data.id_token;
        localStorage.setItem(config.TOKEN_STORAGE, idToken);
      }
      request.headers["Authorization"] = idToken;
    }
    return request;
  });
  return <div>{props.children}</div>;
};

export default Axios;
