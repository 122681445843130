import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Table,
  TableBody,
  Typography,
} from "@material-ui/core";

import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { IIncidence } from "../../../store/interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducer";
import CloudOffIcon from "@material-ui/icons/CloudOff";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const CustomTable: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const incidencesFromStore = useSelector(
    (state: RootState) => state.incidence.filteredIncidences
  );

  const [incidences, setIncidences] = useState<IIncidence[]>([]);

  useEffect(() => {
    setIncidences(incidencesFromStore);
  }, [incidencesFromStore]);

  return incidences.length > 0 ? (
    <Table className={classes.root}>
      <TableHeader />
      <TableBody>
        {incidences.map((incidence, index) => (
          <TableRow data={incidence} key={index + incidence.name} />
        ))}
      </TableBody>
    </Table>
  ) : (
    <Typography
      variant="h3"
      color="secondary"
      style={{
        marginTop: "2rem",
      }}
    >
      <CloudOffIcon /> Sin datos disponibles.
    </Typography>
  );
};

export default withStyles(styles)(CustomTable);
