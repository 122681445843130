import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { IActiveFilters } from "./FilterPane";

interface IProps {
  filterOptions: any[];
  filterKey: string;
  activeFilters: IActiveFilters;
  customHeight?: string;
  handleSelection: (
    keySearch: string,
    criteria: string[],
    currentActiveFilters: IActiveFilters
  ) => void;
}

const CustomFormControlLabel = withStyles((theme) => ({
  root: { height: "33%" },
  label: { fontSize: "0.7rem" },
}))(FormControlLabel);

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      height: "6rem",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const CheckboxGroup: React.FC<PropsType> = (props) => {
  const {
    classes,
    filterOptions,
    activeFilters,
    handleSelection,
    filterKey,
  } = props;

  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    optionsSelected.includes(event.target.value)
      ? setOptionsSelected(
          optionsSelected.filter((x) => x !== event.target.value)
        )
      : setOptionsSelected([...optionsSelected, event.target.value]);
  };

  useEffect(
    () => handleSelection(filterKey, optionsSelected, activeFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsSelected]
  );

  useEffect(
    () => setOptionsSelected(filterOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className={classes.root}
      style={{
        ...(props.customHeight && { height: props.customHeight }),
      }}
    >
      {filterOptions.map((option, i) => (
        <CustomFormControlLabel
          key={i}
          control={
            <Checkbox
              checked={optionsSelected.includes(option)}
              onChange={handleChange}
              name={option}
              value={option}
              color="default"
            />
          }
          label={option}
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(CheckboxGroup);
