import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {
  title: string;
  columnSpan: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: { marginBottom: "1rem" },
    label: {
      margin: "0.40rem 0",
      fontWeight: theme.typography.fontWeightBold,
    },
  });

type ClassKey = "root" | "label";
type PropsType = IProps & WithStyles<ClassKey>;

const FIlterHoC: React.FC<PropsType> = (props) => {
  const { title, children, classes, columnSpan } = props;
  return (
    <div
      className={classes.root}
      style={{
        gridColumn: `${columnSpan}`,
      }}
    >
      <Typography variant="body2" className={classes.label} color="secondary">
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default withStyles(styles)(FIlterHoC);
