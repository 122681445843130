import { ThemeProvider } from "@material-ui/styles";
import { Fade } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import Axios from "./common/Axios";
import Auth from "./common/Auth";
import Topbar from "./common/Topbar";
import Loading from "./common/Loading";
import ErrorComponent from "./common/ErrorComponent";
import { dHubTheme } from "./config/mui";
import { rootReducer } from "./store/reducer";
import Frame from "./modules/Frame";
import { incidencesActions } from "./store/incidence";
import config from "./config/config";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const middleware = applyMiddleware(thunkMiddleware);
export const browserHistory = createBrowserHistory();

export const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(middleware)
);

const App = () => (
  <ThemeProvider theme={dHubTheme}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Auth>
          <Axios>
            <Chore />
          </Axios>
        </Auth>
      </Router>
    </Provider>
  </ThemeProvider>
);

const Chore = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<number | null>(null);

  useEffect(() => {
    const req = async () => {
      try {
        setIsLoading(true);
        await axios.post(`${config.API}/updateDB`);
        const response = await axios.get(`${config.API}/getData`);

        dispatch(incidencesActions.addIncidencesList(response.data));
      } catch (e: any) {
        console.error(e);
        if (e.response?.status) {
          setError(e.response.status);
        } else {
          setError(600);
        }
      } finally {
        setIsLoading(false);
      }
    };

    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Topbar />
      <Fade in={isLoading} mountOnEnter unmountOnExit>
        <div>
          <Loading />
        </div>
      </Fade>

      <Fade in={!isLoading && !error} mountOnEnter unmountOnExit>
        <div>
          <Frame />
        </div>
      </Fade>

      <Fade in={!isLoading && !!error} mountOnEnter unmountOnExit>
        <div>
          <ErrorComponent errorCode={error!} />
        </div>
      </Fade>
    </>
  );
};

export default App;
