import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const TableHeader: React.FC<PropsType> = (props) => {
  const { classes } = props;
  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell>Incidencia</TableCell>
        <TableCell>sistema</TableCell>
        <TableCell>subsistema</TableCell>
        <TableCell>tipología</TableCell>
        <TableCell>planta</TableCell>
        <TableCell>zona</TableCell>
        <TableCell>prioridad</TableCell>
        <TableCell>estado</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(TableHeader);
